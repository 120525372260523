/* eslint space-before-function-paren: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import * as types from './types/mutations-types'

import { filterVariantsByParameters, mapVariantsToParameters } from '../utils/variants'
import { promoCodeCalculator } from './getters'
import { NO_DELIVERY } from '../app'

export default {
    [types.INCREMENT_PRODUCT_QUANTITY] (state, productId) {
        const product = state.products.find(p => p.id === productId)

        product.quantity++
    },
    [types.DECREMENT_PRODUCT_QUANTITY] (state, productId) {
        const product = state.products.find(p => p.id === productId)

        if (product.quantity > 1) {
            product.quantity--
        }
    },

    [types.OPEN_MODAL] (state) {
        state.isModalOpen = true
        document.body.classList.add('body-hide')
    },

    [types.CLOSE_MODAL] (state) {
        state.isModalOpen = false
        document.body.classList.remove('body-hide')
    },

    [types.CHANGE_TOGGLE_PARAMETER] (state, productId) {
        const parameters = []
        const parameter = {}

        Object.keys(state.productVariantsList).forEach(variant => {
            parameter.key = variant
            parameter.value = [
                ...state.productVariantsList[variant],
            ][state.selectedParametersIndexMap[variant]]
            parameters.push({ ...parameter })
        })

        state.draftProduct = [{
            productId,
            quantity: 1,
            parameters,
        }]
    },

    [types.SET_PRODUCT] (state, product) {
        state.product = product

        const variants = {}
        const selectedParametersIndexMap = {}

        product.variants.forEach(
            variant => {
                variant.parameters.forEach(
                    parameter => {
                        if (variants[parameter.key]) {
                            variants[parameter.key].add(parameter.value)
                        } else {
                            selectedParametersIndexMap[parameter.key] = 0
                            variants[parameter.key] = new Set([parameter.value])
                        }
                    },
                )
            },
        )

        state.selectedParametersIndexMap = selectedParametersIndexMap
        state.productVariantsList = variants
    },

    [types.SET_DRAFT] (state, { productId, parameter = [] }) {
        state.draftProduct = [{
            productId,
            quantity: 1,
            parameters: parameter,
        }]
    },

    [types.SET_DELIVERY_PRICES] (state, deliveryPrices) {
        state.daData.deliveryPrices = deliveryPrices
    },

    [types.SET_DELIVERY_PRICES_TEST] (state, deliveryPricesTest) {
        state.daData.deliveryPricesTest = deliveryPricesTest
    },

    [types.SET_DELIVERY_PRICE] (state, deliveryPrice) {
        state.customer.delivery.deliveryPrice = deliveryPrice
    },

    [types.SET_AUTH_PHONE_REQUEST] (state, response) {
        if (response) {
            state.authenticatorData.firstStep = true
            state.authenticatorData.text = response
            state.authenticatorData.textError = null
        } else {
            state.authenticatorData.firstStep = false
            state.authenticatorData.textError = 'Произошка ошибка. Попробуйте позже.'
        }
    },

    [types.SET_AUTH_DATA] (state, authData) {
        if (authData) {
            state.authenticatorData.client = authData
            state.customer.isAuthenticated = true
        } else {
            state.authenticatorData.textError = 'Неправильный код'
        }
    },

    [types.CHANGE_TIME_BLOCK] (state, time) {
        state.timeBlock = state.timeBlock + time
    },

    [types.SET_AMOUNT] (state, amount) {
        state.amountProduct = amount
    },

    [types.SET_IMAGE_PATH] (state, path) {
        state.pathImg = path
    },

    [types.SET_DELIVERY_INTERVALS] (state, deliveryIntervals) {
        state.deliveryIntervals = deliveryIntervals
    },

    [types.SET_PICKUP_POINTS] (state, pickupPoints) {
        state.pickupPoints = pickupPoints
    },

    [types.SET_PROMOCODE] (state, promoCode) {
        state.promoCode.promoCodeFromServer = promoCode
        state.customer.discountPrice = promoCodeCalculator(state)
        state.customer.promoCode = promoCode.name
        state.promoCode.text = ''
    },

    [types.SET_ERROR_PROMOCODE] (state) {
        state.promoCode.text = 'Данного промокода не существует'
    },

    [types.SET_ACTIVE_TOGGLE] (state, keyIndexPairParametersMap) {
        // keyIndexPairParametersMap содержит имя ключа и цифру под которой
        // отрисовывается данный ключ
        const [f, s] = keyIndexPairParametersMap
        state.selectedParametersIndexMap[f] = s

        const parameter = {}

        Object.keys(state.productVariantsList).forEach(variantKey => {
            // variantKey это имя ключа, по-которому обращаются в VariantsList,
            // чтобы вытащить нужный параметр
            parameter[variantKey] = [
                ...state.productVariantsList[variantKey],
            ][state.selectedParametersIndexMap[variantKey]]
        })

        state.activePriceIndex = state.product.variants.findIndex(
            variant => variant.parameters.reduce((acc, param) => {
                const parameterCount = Object.keys(parameter).filter(
                    paramKey => paramKey === param.key && parameter[paramKey] === param.value,
                ).length

                return acc + parameterCount
            }, 0) === Object.keys(parameter).length,
        )
    },

    [types.SET_MAIN_IMAGE] (state, img) {
        state.mainImg = img
    },

    [types.SET_ACTIVE_IMAGE] (state, index) {
        state.mainImg = state.product.images[index]
        state.activeImageIndex = index
    },

    [types.SET_SCREEN_WIDTH] (state, width) {
        state.screenWidth = width
    },

    [types.SET_LOADER_ADDRESS_IN_CUSTOMER] (state, value) {
        state.customer.address.fullStreet = value
    },
    [types.SET_LOADED_ADDRESS_IN_ADDRESS_LOCAL] (state, value) {
        state.daData.address.street = value.street_with_type ?? ''
        state.daData.address.house = value.house ?? ''
        state.daData.address.city = value.city ?? ''
        state.daData.address.fullStreet = value.street_with_type + (value.house ? ', ' + value.house_type_full + ' ' + value.house : '')
    },

    [types.SET_LOADER_ADDRESSES] (state, value) {
        state.daData.loadedAddresses = value.suggestions
    },

    [types.ACTIVATE_PREV_IMAGE] (state) {
        if (state.currentImageIndex > 0) {
            state.currentImageIndex--
        }
    },

    [types.ACTIVATE_NEXT_IMAGE] (state) {
        if (state.currentImageIndex < state.product.images.length - 1) {
            state.currentImageIndex++
        }
    },

    [types.RESET_IMAGE_POSITION] (state) {
        state.currentImageIndex = 0
    },

    [types.RESET_ACTIVE_IMAGE] (state) {
        state.activeImageIndex = 0
    },

    [types.RESET_ACTIVE_TOGGLE] (state) {
        state.selectedParametersIndexMap = {}
    },

    [types.RESET_ACTIVE_PRICE_VARIANT] (state) {
        state.productPriceModifiers = 0
        state.activePriceIndex = 0
    },

    [types.RESET_SELECTED_MODIFIERS] (state) {
        state.selectedModifiers = []
        state.productPriceModifiers = 0
    },

    [types.ADD_PRODUCT] (state, product) {
        product.id += state.selectedModifiers.reduce((acc, cur) => `${acc}#${cur.id}':'${cur.title}`, '')
        const existedProduct = state.products.find(p => p.id === product.id)
        let existedSelectedModifiers = null
        if ((existedProduct)
        && (existedProduct.modifiers.length === state.selectedModifiers.length)) {
            existedSelectedModifiers = state.selectedModifiers.every(
                v => existedProduct.modifiers.some(el => el.id === v.id),
            )
        }

        product.modifiers = [...state.selectedModifiers]
        if (existedProduct && existedSelectedModifiers) {
            existedProduct.quantity++
        } else {
            product.quantity = 1
            state.products.push(product)
        }
    },

    [types.REMOVE_PRODUCT] (state, productId) {
        state.products = state.products.filter(p => p.id !== productId)
    },

    [types.CLEAR_PRODUCTS] (state) {
        state.products = []
    },

    [types.CLEAR_AUTH_REQUEST] (state) {
        state.authenticatorData.text = null
        state.authenticatorData.textError = null
        state.authenticatorData.firstStep = false
        state.authenticatorData.finalStep = false
    },

    [types.CLEAR_AUTH] (state) {
        state.authenticatorData.text = null
        state.authenticatorData.client = {
            buyerId: null,
            bonuses: null,
            rawName: null,
        }
        state.authenticatorData.phone = null
        state.authenticatorData.code = null
        state.customer.isAuthenticated = false
    },

    [types.CLEAR_PROMOCODE] (state) {
        state.promoCode.promoCodeFromServer = null
        state.promoCode.discount = null
        state.customer.discountPrice = null
        state.customer.promoCode = null
    },

    [types.CLEAR_LOAD_ADDRESSES] (state) {
        state.daData.loadedAddresses = null
    },
    [types.CLEAR_DELIVERY_PRICES] (state) {
        state.daData.deliveryPrices = null
        state.daData.deliveryPricesTest = null
    },
    [types.CLEAR_CUSTOMER_ADDRESS] (state) {
        state.customer.address.street = ''
        state.customer.address.house = ''
        state.customer.address.fullStreet = ''
    },

    [types.CLEAR_DA_DATA_DELIVERY_PRICES_LOCAL] (state) {
        state.daData.deliveryPricesTest = null
    },

    [types.UPDATE_CROPPED_IMAGE] (state, value) { state.cropped.image = value },
    [types.UPDATE_CROPPED_IMAGE_ORIGIN] (state, value) { state.cropped.imageOrigin = value },
    [types.UPDATE_CROPPED_IMAGE_DATA] (state, value) { state.cropped.imageData = value },
    [types.SET_SLIDE_LINK_OPTIONS] (state, value) {
        state.slideLinkOptions = {
            ...state.slideLinkOptions, ...value,
        }
    },
    [types.UPDATE_CUSTOMER_NAME] (state, name) { state.customer.name = name },
    [types.UPDATE_CUSTOMER_EMAIL] (state, email) { state.customer.email = email },
    [types.UPDATE_CUSTOMER_PHONE] (state, phone) { state.customer.phone = phone },
    [types.UPDATE_CUSTOMER_MESSAGE] (state, message) { state.customer.message = message },
    [types.UPDATE_CUSTOMER_FILE] (state, file) { state.customer.file = file },
    [types.UPDATE_CUSTOMER_LAST_ORDER_ID] (state, orderId) { state.customer.lastOrderId = orderId },
    [types.UPDATE_CUSTOMER_ALLOW_SPAM] (state, allow) { state.customer.allowSpam = allow },
    [types.UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING] (state, allow) {
        state.customer.allowPersonalDataProcessing = allow
    },
    [types.UPDATE_CUSTOMER_ONLINE_PAYMENT] (state, flag) { state.customer.onlinePayment = flag },
    [types.UPDATE_CUSTOMER_PAYMENT_TYPE] (state, paymentType) { state.customer.paymentType = paymentType },
    [types.UPDATE_CUSTOMER_DELIVERY_TYPE] (state, type) { state.customer.delivery.type = type },
    [types.UPDATE_DA_DATA_DELIVERY_TYPE_LOCAL] (state, type) { state.daData.deliveryTypeLocal = type },
    [types.UPDATE_CUSTOMER_DELIVERY_DATE] (state, date) {
        state.customer.delivery.date = date || (
            state.deliveryIntervals[0]
            && state.deliveryIntervals[0][0]
            && state.deliveryIntervals[0][0].date
        )

        const deliveryInterval = state.deliveryIntervals.find(deliveryDate => {
            if (deliveryDate[0] && deliveryDate[0].date === state.customer.delivery.date) {
                return true
            }
            return false
        })

        if (deliveryInterval !== undefined) {
            state.customer.delivery.interval = deliveryInterval[0].name
            state.customer.delivery.hoursFrom = deliveryInterval[0].timeFrom
            state.customer.delivery.hoursTo = deliveryInterval[0].timeTo
        } else {
            state.customer.delivery.interval = NO_DELIVERY
            state.customer.delivery.hoursFrom = undefined
            state.customer.delivery.hoursTo = undefined
        }
    },

    [types.UPDATE_CUSTOMER_DELIVERY_INTERVAL] (state, interval) {
        const deliveryInterval = state.deliveryIntervals.find(deliveryDate => {
            if (deliveryDate[0].date === state.customer.delivery.date) {
                // выходит как только находит интервал доставки
                return deliveryDate.find(deliveryIntervalLoc => (
                    deliveryIntervalLoc.name === interval
                ))
            }
            return undefined
        })

        state.customer.delivery.interval = interval
        state.customer.delivery.hoursFrom = deliveryInterval.timeFrom
        state.customer.delivery.hoursTo = deliveryInterval.timeTo
    },

    [types.UPDATE_CUSTOMER_DELIVERY_PICKUP_POINT] (state, pickupPoint) {
        state.customer.delivery.pickupPoint = pickupPoint
    },

    [types.UPDATE_PICKUP_POINTS_SELECTED] (state, pickupPoint) {
        state.selectPickupPoint = pickupPoint
    },

    [types.UPDATE_CUSTOMER_ADDRESS_CITY] (state, city) { state.customer.address.city = city },
    [types.UPDATE_CUSTOMER_ADDRESS_FULL_STREET] (state, fullStreet) { state.customer.address.fullStreet = fullStreet },
    [types.UPDATE_CUSTOMER_CHOOSE_ADDRESS] (state, flag) { state.customer.isAddressChosen = flag },
    [types.UPDATE_CUSTOMER_ADDRESS_STREET] (state, street) {
        state.customer.address.street = street
    },
    [types.UPDATE_DA_DATA_ADDRESS_FULL_STREET] (state, street) {
        state.daData.address.fullStreet = street
        state.daData.isStreetEdit = true
    },
    [types.UPDATE_CUSTOMER_ADDRESS_HOUSE] (state, house) { state.customer.address.house = house },
    [types.UPDATE_CUSTOMER_ADDRESS_APARTMENT] (state, apartment) {
        state.customer.address.apartment = apartment
    },
    [types.UPDATE_CUSTOMER_ADDRESS_FLOOR] (state, floor) {
        state.customer.address.floor = floor
    },

    [types.UPDATE_CUSTOMER_ADDRESS_DOOR_CODE] (state, doorCode) {
        state.customer.address.doorCode = doorCode
    },

    [types.UPDATE_CUSTOMER_ADDRESS_ENTRANCE] (state, entrance) {
        state.customer.address.entrance = entrance
    },

    [types.UPDATE_HTTP_IN_PROGRESS] (state, flag) {
        state.httpInProgress = flag
    },

    [types.UPDATE_AUTH_PHONE] (state, phone) { state.authenticatorData.phone = phone },
    [types.UPDATE_AUTH_CODE] (state, code) { state.authenticatorData.code = code },

    [types.UPDATE_PAID_BY_BONUSES_LOCAL] (state, {paidByBonuses, bonusesAvailableToUse}) {
        if (paidByBonuses < 0) {
            return state.authenticatorData.paidByBonusesLocal = 0
        }
        if (bonusesAvailableToUse > state.authenticatorData.client.bonuses) {
            switch (true) {
                case paidByBonuses > state.authenticatorData.client.bonuses:
                    return state.authenticatorData.paidByBonusesLocal = state.authenticatorData.client.bonuses
                case paidByBonuses > bonusesAvailableToUse:
                    return state.authenticatorData.paidByBonusesLocal = bonusesAvailableToUse
            }
        } else {
            switch (true) {
                case paidByBonuses > bonusesAvailableToUse:
                    return state.authenticatorData.paidByBonusesLocal = bonusesAvailableToUse
                case paidByBonuses > state.authenticatorData.client.bonuses:
                    return state.authenticatorData.paidByBonusesLocal= state.authenticatorData.client.bonuses
            }
        }
        state.authenticatorData.paidByBonusesLocal = paidByBonuses
    },

    [types.UPDATE_PROMOCODE] (state, value) { state.promoCode.name = value },

    [types.SELECT_MODIFIER] (state, modifier) {
        // toggle active class
        const currentAmountSelectedModifiers = state.selectedModifiers.filter(
            el => el.groupModifier === state.selectedGroupModifier.apiId,
        ).length

        const modifierClone = { ...modifier }

        modifierClone.groupModifier = state.selectedGroupModifier.apiId
        modifierClone.modifier = modifier.id
        modifierClone.amount = 1
        const findModifierInSelected = state.selectedModifiers.find(
            gm => gm.id === modifierClone.id,
        )

        if (findModifierInSelected) {
            state.selectedModifiers = state.selectedModifiers.filter(
                item => item !== findModifierInSelected,
            )
        } else if (currentAmountSelectedModifiers >= state.selectedGroupModifier.maxAmount) {
            state.selectedModifiers.find((modifierLoc, index) => {
                if (modifierLoc.groupModifier === state.selectedGroupModifier.apiId) {
                    state.selectedModifiers.splice(index, 1)
                    return true
                }
                return false
            })
            state.selectedModifiers.push(modifierClone)
            state.selectedModifiers.sort((a, b) => a.id - b.id)
        } else {
            state.selectedModifiers.push(modifierClone)
            state.selectedModifiers.sort((a, b) => a.id - b.id)
        }
        state.productPriceModifiers = state.selectedModifiers.reduce(
            (sum, modifierLoc) => sum + modifierLoc.price,
            0,
        )
    },

    [types.SELECT_GROUP_MODIFIER] (state, groupModifier) {
        // groupModifier всегда одна и та же ссылка на объект
        if (state.selectedGroupModifier !== groupModifier) {
            state.selectedGroupModifier = groupModifier
        } else {
            state.selectedGroupModifier = null
        }
    },

    [types.SET_MODIFIERS] (state, modifiers) {
        state.modifiers = modifiers
    },

    [types.SET_SLIDES] (state, value) {
        state.slides = value
    },

    [types.SET_CROPPED_IMAGE] (state, image) {
        state.cropped.image = image
    },

    [types.SET_CROPPED_IMAGE_ORIGIN] (state, image) {
        state.cropped.imageOrigin = image
    },

    [types.SET_CROPPED_IMAGE_DATA] (state, data) {
        state.cropped.imageData = data
    },

    [types.SET_SLIDE_PREVIEW] (state, slidePreviewData) {
        state.slidePreview = slidePreviewData
    },

    [types.SET_PARAMETERS_VARIANTS] (state, parameters) {
        state.availableParameters = parameters
    },

    [types.SET_CURRENT_VARIANTS] (state, variants) {
        state.currentVariants = variants
    },

    [types.SET_PAID_BY_BONUSES_IN_CUSTOMER] (state) { state.customer.paidByBonuses = state.authenticatorData.paidByBonusesLocal },

    [types.SET_PARAMETERS_LENGTH] (state, length) {
        state.lengthParameters = length
    },

    [types.SET_OPTIONS_VARIANTS] (state, options) {
        state.filters.options = Object.values(options).filter(i => i)
    },

    [types.SET_PRICE_RANGE] (state, { min, max }) {
        state.filters.price.min = min
        state.filters.price.max = max
    },

    [types.SET_QUESTIONS] (state, questions) {
        state.questions = questions
    },

    [types.SET_CITIES] (state, cities) {
        state.cities = cities
    },

    [types.SET_BONUS_SYSTEM] (state, bonusSystem) {
        state.bonusSystem = bonusSystem
    },

    [types.SET_CONTACTS] (state, contacts) {
        state.shopContacts = contacts
    },

    [types.UPDATE_CUSTOMER_CITY] (state, { city, exact = false }) {
        state.customer.city = city
        state.cityExact = exact
    },

    [types.CLEAR_CUSTOMER_ANSWERS] (state) {
        state.customer.answers = []
    },
    [types.UPDATE_CUSTOMER_ANSWER] (state, { questionId, question, answer }) {
        const existedAnswer = state.customer.answers.find(a => a.questionId === questionId)

        if (existedAnswer) {
            existedAnswer.question = question
            existedAnswer.answer = answer
        } else {
            state.customer.answers.push({
                questionId,
                question,
                answer,
            })
        }
    },

    [types.UPDATE_FILTER_FROM_PRICE] (state, from) {
        state.filters.active.price.from = from
    },
    [types.UPDATE_FILTER_TO_PRICE] (state, to) {
        state.filters.active.price.to = to
    },
    [types.UPDATE_FILTER_OPTIONS] (state, options) {
        state.filters.active.options = options
    },

    [types.TOGGLE_FILTER_OPTION] (state, { key, value }) {
        const activeOption = state.filters.active.options
            .find(option => option.key === key.toLowerCase())
        if (activeOption && activeOption.values.find(v => v === value.toLowerCase())) {
            activeOption.values = activeOption.values.filter(v => v !== value.toLowerCase())
        } else if (activeOption) {
            activeOption.values.push(value.toLowerCase())
        } else {
            state.filters.active.options.push({
                key: key.toLowerCase(),
                values: [value.toLowerCase()],
            })
        }
    },

    [types.TOGGLE_ADDRESS_IS_CHOSEN] (state, isAddressChosen) {
        state.customer.isAddressChosen = isAddressChosen
    },

    [types.CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS] (state) {
        state.products = []
        state.draftProducts = []
    },

    [types.RESET_PRODUCT_PARAMETERS] (state) {
        state.draftProducts.forEach(product => {
            product.parameters = []
            product.discriminated.forEach(discriminated => {
                discriminated.parameters = []
            })
        })
    },

    // TODO: код о дискриминаторах нужно упростить, он не читаем

    [types.CHANGE_DRAFT_PRODUCT_PARAMETER] (state, { id, key, value }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            const existedParameter = existedProduct.parameters.find(o => o.key === key)
            if (existedParameter) {
                existedParameter.value = value
            } else {
                existedProduct.parameters.push({ key, value })
            }
        } else {
            state.draftProducts.push({
                id,
                quantity: 1,
                price: 0,
                parameters: [{ key, value }],
                discriminated: [],
            })
        }

        const draft = state.draftProducts.find(p => p.id === id)

        draft.additionalParameters = mapVariantsToParameters(
            filterVariantsByParameters(state.currentVariants, draft.parameters),
        ).filter(parameter => !draft.parameters.map(p => p.key).includes(parameter.key))
    },
    [types.CHANGE_DRAFT_DISCRIMINATED_PARAMETER] (state, {
        id, key, value, discriminator,
    }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            const existedDiscriminated = existedProduct.discriminated.find(
                d => d.discriminator === discriminator,
            )

            if (existedDiscriminated) {
                const existedParameter = existedDiscriminated.parameters.find(o => o.key === key)

                if (existedParameter) {
                    existedParameter.value = value
                } else {
                    existedDiscriminated.parameters.push({ key, value })
                }
            } else {
                existedProduct.discriminated.push({
                    discriminator,
                    quantity: 1,
                    parameters: [{ key, value }],
                })
            }
        } else {
            state.draftProducts.push({
                id,
                quantity: 1,
                price: 0,
                parameters: [],
                discriminated: [{
                    discriminator,
                    quantity: 1,
                    parameters: [{ key, value }],
                }],
            })
        }

        const draft = state.draftProducts
            .find(p => p.id === id).discriminated
            .find(d => d.discriminator === discriminator)

        draft.additionalParameters = mapVariantsToParameters(
            filterVariantsByParameters(state.currentVariants, draft.parameters),
        ).filter(parameter => !draft.parameters.map(p => p.key).includes(parameter.key))
    },

    [types.CHANGE_DRAFT_PRODUCT_QUANTITY] (state, { id, delta, price }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            existedProduct.price = price
            if ((delta < 0 && existedProduct.quantity > Math.abs(delta)) || delta > 0) {
                existedProduct.quantity += delta
            }
        } else {
            state.draftProducts.push({
                id,
                quantity: delta > 0 ? delta + 1 : 1,
                price,
                parameters: [],
                discriminated: [],
            })
        }
    },
    [types.CHANGE_DRAFT_DISCRIMINATED_QUANTITY] (state, {
        id, delta, price, discriminator,
    }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            existedProduct.price = price
            const existedDiscriminated = existedProduct.discriminated.find(
                d => d.discriminator === discriminator,
            )

            if (existedDiscriminated) {
                if ((delta < 0 && existedDiscriminated.quantity > Math.abs(delta)) || delta > 0) {
                    existedDiscriminated.quantity += delta
                }
            } else {
                existedProduct.discriminated.push({
                    discriminator,
                    quantity: delta > 0 ? delta + 1 : 1,
                    parameters: [],
                })
            }
        } else {
            state.draftProducts.push({
                id,
                quantity: 1,
                price,
                parameters: [],
                discriminated: [{
                    discriminator,
                    quantity: delta > 0 ? delta + 1 : 1,
                    parameters: [],
                }],
            })
        }
    },
}
